<nav
  class="navbar navbar-expand-lg fixed-top navbar-light bg-light"
  style="z-index: 998"
>
  <button
    class="navbar-toggler"
    type="button"
    (click)="toggleNavbar()"
    data-toggle="collapse"
    data-target="#navbarTogglerDemo01"
    aria-controls="navbarTogglerDemo01"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div
    class="collapse navbar-collapse"
    id="navbarTogglerDemo01"
    [ngClass]="{ show: navbarOpen }"
  >
    <!-- <a class="navbar-brand" href="#">Hidden brand</a> -->
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
      <li class="nav-item active">
        <a class="nav-link" href="Home#Hero"
          >Home <span class="sr-only"></span
        ></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="Home#section1">Story</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="Home#Gallery">Gallery</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="Home#Featured">Art Mediums</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="Home#Contact">Contact</a>
      </li>
    </ul>
  </div>
</nav>
