

<div>
    <div *ngIf="!signedIn" id="login_secion">
        <input type="text" name="email_input" id="email_input" placeholder="admin@gmail.com" 
        class="login-section-item" [(ngModel)]="email" autocomplete="off">

        <input type="password" name="password_input" id="password_input" placeholder="******" 
        class="login-section-item" [(ngModel)]="password" autocomplete="off">

        <div id="login_div" class="login-section-item">
            <h5 style="width: 100%;text-align: center;color: rgb(240, 240, 240);" (click)="login()">Login</h5>
        </div>
    </div>
    
    <div *ngIf="signedIn" id="logout-div" (click)="logOut()">
        <h4 style="width: 100%;text-align: center;color: rgb(240, 240, 240);" >Logout</h4>
    </div>

</div>