<div class="galleryimgcontainer">
  <a (click)="showModal()"
    ><img src="{{ artwork.highRes_imgURL }}" class="galleryimg"
  /></a>
  <h4 *ngIf="!artwork.sold_out">{{ artwork.name }}</h4>
  <h4 *ngIf="artwork.sold_out">{{ artwork.name }} (Sold)</h4>
</div>

<nz-modal
  [(nzVisible)]="isVisible"
  (nzOnCancel)="handleCancel()"
  (nzOnOk)="handleOk()"
  nzWidth="80vw"
  class="modalcontainer"
>
  <ng-container *nzModalContent>
    <div class="flexWrapper">
      <div class="imageHolder">
        <img
          class="imagebox"
          nz-image
          nzSrc="{{ artwork.highRes_imgURL }}"
          alt=""
        />
        <i>click on the image to zoom </i>
      </div>
      <div class="contentbox">
        <br />
        <h2>{{ artwork.name }}</h2>
        <br />
        <hr />
        <p>
          {{ artwork.description }}
        </p>
        <hr />
        <br />
        <p>
          Size : {{ artwork.canvas_size }} <br />
          Medium : {{ artwork.art_medium }} <br />
          Framed : {{ framed }} <br /><br />
          {{ artwork.price }}<br />
          Contact me for purchase         
        </p>
        *Rate of conversion 203 LKR per USD
        <div id="smicons">
          <a href="https://www.instagram.com/paint.and.magic/"
            ><img src="assets/smlogo (2).png" alt="instragram"
          /></a>
          <a href="https://www.facebook.com/Swendrini-Artist-655051038331086/"
            ><img src="assets/smlogo (3).png" alt="facebook"
          /></a>
          <a
            ><img
              src="assets/smlogo (4).png"
              alt="whatsapp"
              (click)="createBasicMessage()"
          /></a>
        </div>
      </div>
    </div>
  </ng-container>
</nz-modal>
