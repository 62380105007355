import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private firebaseAuth : AngularFireAuth
  ) { }

  
  async anonymousLogin(){
    await this.firebaseAuth.signInAnonymously()
    .then(()=>{
      console.log("Anonymously Signed In");
    })
    .catch((err)=>{
      console.log("Anonymous Sign-In Faliure. || Please Contact Developer");
      console.log(err);
    })
  }

}
