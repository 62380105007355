// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase:{
    apiKey: "AIzaSyAXCcwuPp0uISBnxTju6N1dMSrtKu5lUsM",
    authDomain: "artbyswen-af717.firebaseapp.com",
    projectId: "artbyswen-af717",
    storageBucket: "artbyswen-af717.appspot.com",
    messagingSenderId: "929872137580",
    appId: "1:929872137580:web:53d58750ed2c1b7bd07749",
    measurementId: "G-TM3NBFBK9Q"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
