import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-sectionone',
  templateUrl: './sectionone.component.html',
  styleUrls: ['./sectionone.component.css'],
})
export class SectiononeComponent implements OnInit {
  constructor(private message: NzMessageService) {}

  displayAll: boolean;

  ngOnInit(): void {
    this.displayAll = false;
  }

  showMoreText() {
    this.displayAll = true;
  }

  showLessText() {
    this.displayAll = false;
  }

  createBasicMessage(): void {
    this.message.info('My Phone No : (+94) 77735 3330');
  }
}
