import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-read-all-section',
  templateUrl: './read-all-section.component.html',
  styleUrls: ['./read-all-section.component.css'],
})
export class ReadAllSectionComponent implements OnInit {
  constructor(private message: NzMessageService) {}

  ngOnInit(): void {}

  createBasicMessage(): void {
    this.message.info('My Phone No : (+94) 77735 3330');
  }
}
