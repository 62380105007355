<div class="artrow">

    <div class="artcol">
        <img
        *ngFor="let artwork_1 of artworks_col_1"
        [style.outline]="getColor(artwork_1.generated_id)"
        [src]="artwork_1.highRes_imgURL"
        [alt]="artwork_1.highRes_imgURL"
        (click)="selectImg(artwork_1.generated_id)"
        >
      </div>
  
      <div class="artcol">
        <img
        *ngFor="let artwork_2 of artworks_col_2"
        [style.outline]="getColor(artwork_2.generated_id)"
        [src]="artwork_2.highRes_imgURL"
        [alt]="artwork_2.highRes_imgURL"
        (click)="selectImg(artwork_2.generated_id)"
        >
        
      </div>
  
      <div class="artcol">
        <img
        *ngFor="let artwork_3 of artworks_col_3"
        [style.outline]="getColor(artwork_3.generated_id)"
        [src]="artwork_3.highRes_imgURL"
        [alt]="artwork_3.highRes_imgURL"
        (click)="selectImg(artwork_3.generated_id)"
        >
      </div>

      
</div>
