import { Injectable } from '@angular/core';
import { Artwork } from '../interfaces/artwork';

@Injectable({
  providedIn: 'root'
})
export class PayloadConverterService {

  constructor() { }


  toArtworks(payload){
    //Does not handle single artwork
    let artworks : Artwork[] = [];

    let arrayContent : Artwork[] = payload.data()["map_array"];

    arrayContent.forEach(artwork =>{
      let newArt : Artwork ={
        "generated_id" : artwork["generated_id"],
        "highRes_imgURL" : artwork["highRes_imgURL"],
        "lowRes_imgURL":artwork["lowRes_imgURL"],
        "name" : artwork["name"],
        "price":artwork["price"],
        "sold_out" : artwork["sold_out"],
        "description" : artwork["description"],
        "canvas_size" : artwork["canvas_size"],
        "art_medium" : artwork["art_medium"],
        "is_framed" : artwork["is_framed"]
      };
      artworks.push(newArt);
    });
    return artworks;
  }


}
