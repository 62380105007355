import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Artwork } from 'src/app/interfaces/artwork';
import { CloudStorageService } from 'src/app/services/cloud-storage.service';
import { FirestoreDBService } from 'src/app/services/firestore-db.service';

@Component({
  selector: 'app-gallery-item',
  templateUrl: './gallery-item.component.html',
  styleUrls: ['./gallery-item.component.css']
})
export class GalleryItemComponent implements OnInit {

  constructor(
    private storage : CloudStorageService,
    private db : FirestoreDBService
  ) { }

  @Input("art_id") art_id : number;

  @Input("img_src") img_src : string;
  @Input("art_name") art_name : string;
  @Input("price") price : number;
  @Input("is_sold_out") is_sold_out : boolean;
  @Input("is_framed") is_framed : boolean;
  @Input("item_description") item_desc : string;
  @Input("canvas_size") canvas_size : string;
  @Input("art_medium") art_medium : string;

  @Output("save_event_id") save_event_id = new EventEmitter<Artwork>();
  
  img_src_copy;
  art_name_copy;
  price_copy;
  is_sold_out_copy;
  is_framed_copy;
  item_desc_copy;
  canvas_size_copy;
  art_medium_copy;

  attached_image : File;

  ngOnInit(): void {
    this.img_src_copy = this.img_src
    this.art_name_copy = this.art_name;
    this.price_copy = this.price;
    this.is_sold_out_copy = this.is_sold_out;
    this.is_framed_copy = this.is_framed;
    this.item_desc_copy = this.item_desc;
    this.canvas_size_copy = this.canvas_size;
    this.art_medium_copy = this.art_medium;
    this.startChangesChecker();
  }

  toggleSoldOut(){
    this.is_sold_out = !this.is_sold_out;
  }

  toggleIsFramed(){
    this.is_framed = !this.is_framed;
  }

  setMedium(type : string){
    this.art_medium = type;
  }

  startChangesChecker(){
    setTimeout(()=>{
      this.checkChanges();
      this.startChangesChecker();
    } , 200)
  }

  is_updated = false;
  checkChanges(){
    let changes_found = false
    if(this.art_name_copy != this.art_name){
      changes_found = true;
    }
    if(this.price_copy != this.price){
      changes_found = true;
    }
    if(this.is_sold_out_copy != this.is_sold_out){
      changes_found = true;
    }
    if(this.is_framed_copy != this.is_framed){
      changes_found = true;
    }
    if(this.item_desc_copy != this.item_desc){
      changes_found = true;
    }
    if(this.canvas_size_copy != this.canvas_size){
      changes_found = true;
    }
    if(this.art_medium_copy != this.art_medium){
      changes_found = true;
    }

    this.is_updated = changes_found;
  }


  saveChanges(){
    let artwork_details_new : Artwork = {
      "generated_id" : this.art_id,
      "art_medium" : this.art_medium,
      "canvas_size" : this.canvas_size,
      "description" : this.item_desc,
      "highRes_imgURL" : this.img_src,
      "is_framed" : this.is_framed,
      "lowRes_imgURL" : "",
      "name" : this.art_name,
      "price" : this.price,
      "sold_out" : this.is_sold_out
    }
    this.save_event_id.emit(artwork_details_new);
  }


  uploadImg(event) {
    this.storage.uploadGalleryImg(event.target.files[0] , this.art_id);
  }

  delete(){
    this.db.deleteArtwork(this.art_id);
  }
  

}

