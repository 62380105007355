import { Component, OnInit } from '@angular/core';
import { Artwork } from 'src/app/interfaces/artwork';
import { FirestoreDBService } from 'src/app/services/firestore-db.service';
import { CloudStorageService } from 'src/app/services/cloud-storage.service';
import { PayloadConverterService } from 'src/app/services/payload-converter.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class adminComponent implements OnInit {

  constructor(
    private db: FirestoreDBService,
    private storage: CloudStorageService,
    private converter : PayloadConverterService
  ) { }

  artworks: any[] = [];

  fileInput: File;
  file_tag: string;

  jsonBundleData: any = {};
  keys: any[] = [];

  uploadStatus: any = "[]";

  view : string = "image_detail"
  img_src = ""
  artwork_arr : Artwork[] = [];

  ngOnInit(): void {
    this.db.nonGalImgLinks().valueChanges().subscribe(res => {
      let data = res;
      this.img_src = data["test-img"];
      this.jsonBundleData = data;

      this.keys = Object.keys(data);
    })

    this.getArtwork()
  }

  setView(view){
    this.view = view;
  }

  setImage(event) {
    this.fileInput = event.target.files[0];
  }

  uploadImg() {
    if (this.fileInput == undefined) return

    this.uploadStatus = "[Uploading]";
    this.storage.uploadHomeImg(this.fileInput, this.file_tag).subscribe(
      res => {
        console.log(res);
        this.uploadStatus = `[${res}]`
      }
    );
  }

  deleteOtherRecord(key) {
    this.storage.deleteImg(this.jsonBundleData, key);
  }

  
  getArtwork(){
    this.db.GetSubscribableArtworkData().subscribe((data: Artwork[]) =>{
      this.artwork_arr = data["map_array"]
    })
  }

  UpdateArtwork(updated_artwork : Artwork){
    //Updating Artwork Array according to local changes
    let updated_art_list : Artwork[] = [];
    this.artwork_arr.forEach((record : Artwork) =>{
      if(record.generated_id == updated_artwork.generated_id){
        updated_art_list.push(updated_artwork);
        console.log("Updated")
      }else{
        updated_art_list.push(record);
        console.log("Just Added")
      }
    })

    //Uploading changes to Firebase
    this.db.setArtworkData(updated_art_list).then(()=>{
      console.log("[Artwork Update] - Uploaded Changes")
    })
  }

  createNew(){
    this.db.getArtworkData().then((data : Artwork[])=>{
      let available_index_id = 1 //This is the generated_id on an artwork

      while(true){
        let valid = true
        data.forEach((artwork : Artwork) =>{
          if(artwork.generated_id == available_index_id){
            valid = false
          } 
        })
        if(valid){
          break
        }
        available_index_id++;
      }
      console.log("Generated ID : " + available_index_id);
      
      let emptyArtwork : Artwork = {
        "art_medium" : "",
        "canvas_size" : "",
        "description" : "" ,
        "generated_id" : available_index_id,
        "highRes_imgURL" : "",
        "is_framed" : false,
        "name" : "",
        "price" : 0,
        "sold_out" : false,
        "lowRes_imgURL" : ""
      }

      data.push(emptyArtwork);
      return this.db.setArtworkData(data)
    }).then(()=>{
      console.log("New Empty Artwork Added")
    })


  }

}
