import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GalleryComponent } from './pages/gallery/gallery.component';
import { SectiononeComponent } from './pages/homepage/components/sectionone/sectionone.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { adminComponent } from './pages/admin/admin.component';

const routes: Routes = [
  { path: '', redirectTo: '/Home', pathMatch: 'full' },
  { path: 'Home', component: HomepageComponent },
  { path: 'admin', component: adminComponent },
  { path: 'Story', component: SectiononeComponent },
  { path: 'ARTGallery/:medium', component: GalleryComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
