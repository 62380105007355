<div class="footer">
  <div id="line"></div>

  <p>All Rights Reserved Swendrini Gunasekara art 2021</p>

  <div id="smicons">
    <a href="https://www.facebook.com/Swendrini-Artist-655051038331086/"
      ><img src="assets/smlogo (3).png" alt="facebook"
    /></a>
    <a href="https://www.instagram.com/artbyswen/"
      ><img src="assets/smlogo (2).png" alt="instragram"
    /></a>
  </div>
</div>
