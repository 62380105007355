<div class="pageContainer">
  <app-navbar></app-navbar>
  <div class="page">
    <div class="banner" id="Hero" style="background-image: url({{bgUrl}});">
      <img id="nametxt" src="assets/nametxt.png" alt="title" />
      <div class="downArrow bounce">
        <img width="40" height="40" alt="" src="assets/arrow.png" />
      </div>
    </div>

    <app-sectionone></app-sectionone>
    <div
      class="main"
      id="Gallery"
      style="background-image: url({{parralaxBgUrl}});"
    >
      <div class="gallerytopbox"></div>
      <img id="galleryimg" alt="Gallery" src="assets/gallery.png" />
      <br />
      <button id="gallerybtn">
        <a [routerLink]="['/ARTGallery' , 'Acrylics']"
          ><img id="btnimg" alt="->" src="assets/arrowbtn.png"
        /></a>
      </button>
    </div>

    <div class="main" id="Featured">
      <div id="featuredContainer">
        <br />
        <img id="featuredimg" alt="Featuring" src="assets/featuredarttxt.png" />
        <div class="container">
          <div class="card">
            <div
              class="box"
              style="background-image: url({{card_charcoal}});"
            >
              <div class="content">
                <h3>Acrylics</h3>
                <p>
                  Acrylic being a water based medium, becomes water-resistant
                  when dry. It is also a medium that allows an artist the
                  ability to achieve the beauty of transparency such as in Water
                  colour painting or the texture and depths of Oils. Swen mainly
                  uses Gesso primed stretched canvas for her Acrylic work.
                </p>
                <a [routerLink]="['/ARTGallery' , 'Acrylics']">View in gallery</a>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="box" style="background-image: url({{card_oils}});">
              <div class="content">
                <h3>Oils</h3>
                <p>
                  Origins of oil Painting date as far back as the 7th Century.
                  Oils allow an artist to play with texture and vibrant hues and
                  many, many shades of colour. Swen primarily uses Linseed oil
                  for mixing pigments and Turpentine for thinning. All Oils are
                  painted by her on Gesso primed stretched canvas.
                </p>
                <a [routerLink]="['/ARTGallery' , 'Oils']">View in gallery</a>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="box" style="background-image: url({{card_other}});">
              <div class="content">
                <h3>Others</h3>
                <p>
                  Find a collection of Watercolour, Charcoal as well as
                  interesting combination of mixed media painted by the artist.
                </p>
                <a [routerLink]="['/ARTGallery' , 'Other']">View in gallery</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="main" id="Contact">
      <img id="Contactimg" alt="Contact" src="assets/Contacttxt.png" />
      <p>
        Contact me for purchases , and for more details about my art collection
      </p>

      <form
        action="mailto:seneshsirimanne@gmail.com"
        method="post"
        enctype="text/plain"
      >
        <div id="ContactFlex">
          <div id="contactSection1">
            <input type="text" id="fname" name="name" placeholder="Your Name" />
            <input
              type="text"
              id="fname"
              name="mail"
              placeholder="Your Email"
            />
            <textarea
              type="text"
              name="comment"
              placeholder="Message"
            ></textarea>
            <div
              id="submitBox"
              style="display: flex; justify-content: flex-end"
            >
              <input
                class="subbtn"
                type="submit"
                value="Send"
                style="
                  height: 40px;
                  width: 25%;
                  padding-left: 0;
                  margin-right: 5px;
                "
              />
              <input
                class="subbtn"
                type="reset"
                value="Reset"
                style="height: 40px; width: 25%; padding-left: 0"
              />
            </div>
          </div>

          <div id="contactSection3"></div>

          <div id="contactSection2">
            <div class="contacttxt">
              <img src="assets/email.png" alt="whatsapp" />
              <div><p>artbyswen@gmail.com</p></div>
            </div>
            <div class="contacttxt">
              <img src="assets/phone.png" alt="whatsapp" />
              <div><p>+94 77 735 3330</p></div>
            </div>
            <div class="contacttxt">
              <img src="assets/location.png" alt="whatsapp" />
              <div><p>Colombo</p></div>
            </div>

            <div id="smicons">
              <a
                href="https://www.facebook.com/Swendrini-Artist-655051038331086/"
                ><img src="assets/smlogo (3).png" alt="facebook"
              /></a>
              <a href="https://www.instagram.com/artbyswen/"
                ><img src="assets/smlogo (2).png" alt="instragram"
              /></a>
            </div>
          </div>
        </div>
      </form>
      <app-footer></app-footer>
    </div>
  </div>
</div>
