<div class="artwork-row">
            

    <!-- Upper Section of the GUI -->
    <div class="artwork-menu-upper">
        <div>
            <img [src]="img_src" alt="" class="gal-img" *ngIf="img_src!=''">
            <div class="img-btn-cover">
                <input type="file" name="image_upload" id="image_upload" accept="image/png, image/jpeg" (change)="uploadImg($event)">
            </div>
        </div>

        <!-- Upper First Column -->
        <div id="col-div-main-1">
            <div class="col-div-1">
                <h6 class="col-div-text-1">Name :</h6>
                <input type="text" [(ngModel)]="art_name">
            </div>

            <div class="col-div-1">
                <h6 class="col-div-text-1">Price :</h6>
                <input type="text" [(ngModel)]="price">
            </div>
            <div class="col-div-1"> 
                <h6 class="col-div-text-1">Sold Out :</h6>
                <div class="yes-no-grouped">
                    <div class="yes-no-div" *ngIf="!is_sold_out" (click)="toggleSoldOut()">
                        <h6 style="color: black;text-align: center;">Yes</h6>
                    </div>
                    <div class="yes-no-div highlighted" *ngIf="is_sold_out" >
                        <h6 style="color: black;text-align: center;">Yes</h6>
                    </div>

                    <div class="yes-no-div" *ngIf="is_sold_out" (click)="toggleSoldOut()">
                        <h6 style="color: black;text-align: center;">No</h6>
                    </div>
                    <div class="yes-no-div highlighted" *ngIf="!is_sold_out">
                        <h6 style="color: black;text-align: center;">No</h6>
                    </div>
                </div>
            </div>
            <div class="col-div-1">
                <h6 class="col-div-text-1">Framed :</h6>
                <div class="yes-no-grouped">
                    <div class="yes-no-div highlighted" *ngIf="is_framed">
                        <h6 style="color: black;text-align: center;">Yes</h6>
                    </div>
                    <div class="yes-no-div" *ngIf="!is_framed" (click)="toggleIsFramed()">
                        <h6 style="color: black;text-align: center;">Yes</h6>
                    </div>
                    
                    <div class="yes-no-div highlighted" *ngIf="!is_framed">
                        <h6 style="color: black;text-align: center;">No</h6>
                    </div>
                    <div class="yes-no-div" *ngIf="is_framed"  (click)="toggleIsFramed()">
                        <h6 style="color: black;text-align: center;">No</h6>
                    </div>
                </div>
                
            </div>
        </div>

        <div class="vertiacal-line"></div>

        <!-- Upper Second Column -->
        <div>
            <h6 style="padding-bottom: 10px;">Description</h6>
            <textarea name="description" id="description" class="description" cols="30" rows="10" [(ngModel)]="item_desc"></textarea>
        </div>

        <div class="vertiacal-line"></div>

        
        <div>
            <div class="canvas-type-div">
                <h6 class="canvas-size-text">Canvas Size</h6>
                <input type="text" [(ngModel)]="canvas_size">
            </div>
            
            <h6 class="art-medium-heading">Art Medium</h6>

            <div class="art-medium-selection-div">
                <div class="art-medium-row">
                    <div class="art-medium-type" *ngIf="art_medium != 'Oils'" (click)="setMedium('Oils')">
                        <h4 class="art-medium-text">Oils</h4>
                    </div>
                    <div class="art-medium-type highlighted" *ngIf="art_medium == 'Oils'">
                        <h4 class="art-medium-text">Oils</h4>
                    </div>

                    <div class="art-medium-type" *ngIf="art_medium != 'Acrylics'" (click)="setMedium('Acrylics')">
                        <h4 class="art-medium-text">Acrylics</h4>
                    </div>
                    <div class="art-medium-type highlighted" *ngIf="art_medium == 'Acrylics'">
                        <h4 class="art-medium-text">Acrylics</h4>
                    </div>

                    <div class="art-medium-type" *ngIf="art_medium != 'Water Colour'" (click)="setMedium('Water Colour')">
                        <h4 class="art-medium-text">Water Colour</h4>
                    </div>
                    <div class="art-medium-type highlighted" *ngIf="art_medium == 'Water Colour'">
                        <h4 class="art-medium-text">Water Colour</h4>
                    </div>
                </div>

                <div class="art-medium-row">
                    <div class="art-medium-type" *ngIf="art_medium != 'Charcoal'" (click)="setMedium('Charcoal')">
                        <h4 class="art-medium-text">Charcoal</h4>
                    </div>
                    <div class="art-medium-type highlighted" *ngIf="art_medium == 'Charcoal'">
                        <h4 class="art-medium-text">Charcoal</h4>
                    </div>

                    <div class="art-medium-type" *ngIf="art_medium != 'Pen&Ink'" (click)="setMedium('Pen&Ink')">
                        <h4 class="art-medium-text">Pen&Ink</h4>
                    </div>
                    <div class="art-medium-type highlighted" *ngIf="art_medium == 'Pen&Ink'">
                        <h4 class="art-medium-text">Pen&Ink</h4>
                    </div>

                    <div class="art-medium-type" *ngIf="art_medium != 'Other'" (click)="setMedium('Other')">
                        <h4 class="art-medium-text">Other</h4>
                    </div>
                    <div class="art-medium-type highlighted" *ngIf="art_medium == 'Other'">
                        <h4 class="art-medium-text">Other</h4>
                    </div>
                </div>
            </div>

        </div>


    </div>

    <div class="horizontal-line"></div>

    <!-- Bottom Section -->
    <div class="bottom-options" >
        <div id="save-btn" class="bottom-btn" *ngIf="is_updated" (click)="saveChanges()">
            <h4 class="bottom-option-text">Save Changes</h4>
        </div>
        <div id="delete-btn" class="bottom-btn" (click)="delete()">
            <h4 class="bottom-option-text" style="color: white;">Delete</h4>
        </div>
    </div>


</div>
