<div id="body">

    <div id="top-options">
        <div id="to_site_btn">
            <h3 id="to_site" routerLink="">Back To Site</h3>
        </div>
        <app-login-logout #authComp></app-login-logout>
    </div>

    <div *ngIf="!authComp.signedIn" id="blank-space">
        <h6 id="login-error-msg">{{authComp.login_error_cause}}</h6>
    </div>

    <div id="gallery-img-section" *ngIf="authComp.signedIn">
        <h4 style="color: white; text-align: center;">Gallery Images</h4>
        <br>
        <div class="menu_option_container">
            <div class="menu_option" (click)="setView('image_detail')">
                <h4 class="menu_option">Edit Images</h4>
            </div>
            <div class="menu_option" (click)="setView('gallery_sort')">
                <h4 class="menu_option">Edit Order (Under Construction)</h4>
            </div>
        </div>
        <br>

        <app-gallery-sort *ngIf="view == 'gallery_sort'"></app-gallery-sort>

        <ul *ngIf="view == 'image_detail'">
            <li *ngFor="let child_artwork of artwork_arr" class="gal-comp">
                <app-gallery-item [art_id]="child_artwork.generated_id" [img_src]="child_artwork.highRes_imgURL"
                    [art_name]="child_artwork.name" [price]="child_artwork.price" [is_sold_out]="child_artwork.sold_out"
                    [is_framed]="child_artwork.is_framed" [item_description]="child_artwork.description"
                    [canvas_size]="child_artwork.canvas_size" [art_medium]="child_artwork.art_medium"
                    (save_event_id)="UpdateArtwork($event)"></app-gallery-item>
            </li>
        </ul>

        <div *ngIf="view == 'image_detail'" id="new-artwork-btn-div" (click)="createNew()">
            <div id="new-artwork-btn">
                <h5>Create New</h5>
            </div>
        </div>
    </div>

</div>


<div *ngIf="authComp.signedIn">
    <img src={{img_src}} alt="test-img missing" style="height: 200px;">

    <h3 style="text-decoration: underline;">Admin Portal</h3>
    <div id="bundlePicker">
        <div id="jsonBundles">
            Other link Jsons

            <ul>
                <li *ngFor="let key of keys">
                    <div id="tagDelete">
                        <input type="button" value=" X " style="background-color: rgb(255, 137, 137); color: white;"
                            (click)="deleteOtherRecord(key)">
                        <input type="button" value={{key}}>
                    </div>
                </li>
            </ul>
        </div>

        <div>
            <div *ngFor="let key of keys">
                <h5>{{key}} : </h5>
                <h6>{{jsonBundleData[key]}}</h6>
            </div>
        </div>
    </div>

    <div>
        <h5 style="text-decoration: underline;">Image Upload Section</h5>
        <input type="file" (change)="setImage($event)">
        <br>
        <input [(ngModel)]="file_tag" placeholder="imageTag">
        <br>
        <input type="button" value="Upload Image" (click)="uploadImg()">
        <br>
        {{uploadStatus}}
    </div>
</div>