import { Component, Input, OnInit } from '@angular/core';
import { Artwork } from 'src/app/interfaces/artwork';
import { NzMessageService } from 'ng-zorro-antd/message';
@Component({
  selector: 'app-artwork',
  templateUrl: './artwork.component.html',
  styleUrls: ['./artwork.component.css'],
})
export class ArtworkComponent implements OnInit {
  @Input('title') art_title: string;
  @Input('artsrc') art_src: string;

  @Input('artwork_data') artwork: Artwork;

  framed: string;

  //change this attributes to any thing you want , you can even pass objects with input
  // watch this vid to lean ->  https://youtu.be/bT9yd1-c6xA

  isVisible = false;

  constructor(private message: NzMessageService) {}

  createBasicMessage(): void {
    this.message.info('My Phone No : (+94) 77735 3330');
  }

  ngOnInit(): void {
    this.framed = "No"
    try{
      if(this.artwork.is_framed){
        this.framed = "Yes"
      }
    }catch(err){
      console.log("Error : " , err)
      console.log("Entered Artwork : " , this.artwork)
    }
  }

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    console.log('Button ok clicked!');
    this.isVisible = false;
  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
  }
}
