import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { PayloadConverterService } from './payload-converter.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Artwork } from '../interfaces/artwork';


@Injectable({
  providedIn: 'root'
})
export class FirestoreDBService {

  constructor(
    private db: AngularFirestore,
    private converter: PayloadConverterService,
    private http: HttpClient
  ) { }

  getArtworkData() {
    return new Promise<Artwork[]>((resolve, reject) => {
      this.db.collection("Artwork").doc("all_artwork").get().toPromise().then(res => {
        let artworks = this.converter.toArtworks(res);
        resolve(artworks);
      });
    });
  }

  setArtworkData(updated_artwork : Artwork[]){
    return this.db.collection("Artwork").doc("all_artwork").set({
      "map_array": updated_artwork
    });
  }

  deleteArtwork(artwork_id){
    this.getArtworkData().then((artworks : Artwork[])=>{
      let filtered = artworks.filter( (artwork : Artwork ) =>{
        return artwork.generated_id != artwork_id
      })

      this.setArtworkData(filtered)
    })
  }

  GetSubscribableArtworkData() {
    return this.db.collection("Artwork").doc("all_artwork").valueChanges()
  }

  nonGalImgLinks(){
    return this.db.collection("other").doc("non_gallery_images");
  }  

  getFirestore(){
    return this.db.firestore;
  }

  getIp() {
    return this.http.get("https://api.ipify.org/?format=json");
  }

  //---------------------------------------------------------------------------------------------------
  //One Time Methods For Specific Firestore related tasks

  //Sets a specific art medium to '' , Esseentially removing it from the existing data
  async resetSpecificArtMedium(medium : string){
    let artworkData : Artwork[] = await this.getArtworkData();
    
    let altered : Artwork[] = [];
    artworkData.forEach((artwork : Artwork)=>{
      if(artwork.art_medium == medium){
        console.log("Artwork : " , artwork)
        artwork.art_medium = ""
        altered.push(artwork);
      }else{
        altered.push(artwork);
      }
    })

    await this.setArtworkData(altered);
    console.log(`replaced ${medium} with '' `)
  }

}
