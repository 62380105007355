import { Component, OnInit } from '@angular/core';
import { Artwork } from 'src/app/interfaces/artwork';
import { FirestoreDBService } from 'src/app/services/firestore-db.service';

@Component({
  selector: 'app-gallery-sort',
  templateUrl: './gallery-sort.component.html',
  styleUrls: ['./gallery-sort.component.css']
})
export class GallerySortComponent implements OnInit {


  artworks_col_1: Artwork[] = [];
  artworks_col_2: Artwork[] = [];
  artworks_col_3: Artwork[] = [];

  all_artworks: Artwork[] = [];

  constructor(
    private db : FirestoreDBService
  ) { }

  ngOnInit(): void {
    this.getArtworks();
  }

  calculateArtworksPerColumn() {
    let artwork_count = this.all_artworks.length;
    // let artwork_count = 6;

    let col_len = {
      "1": Math.floor(artwork_count / 3),
      "2": Math.floor(artwork_count / 3),
      "3": Math.floor(artwork_count / 3)
    }

    switch (artwork_count % 3) {
      case 1:
        col_len["1"] += 1
        break;
      case 2:
        col_len["1"] += 1
        col_len["2"] += 1
    }

    return col_len
  }

  getArtworks() { 
    this.db.getArtworkData().then((data: Artwork[]) => {
      this.all_artworks = data;
      // this.all_artworks = data.splice(0,2)
      // data = this.all_artworks

      console.log(this.all_artworks.length)


      let col_len = this.calculateArtworksPerColumn();
      for (let x = 0; x < col_len["1"]; x++) {
        this.artworks_col_1.push(data[x]);
      }
      data.splice(0, col_len["1"])

      for (let x = 0; x < col_len["2"]; x++) {
        this.artworks_col_2.push(data[x]);
      }

      data.splice(0, col_len["2"])
      for (let x = 0; x < col_len["3"]; x++) {
        this.artworks_col_3.push(data[x]);
      }

    })
  }

  selectionColor = 'solid 5px red'
  getColor(id){

    if(id == this.selectionOne || id == this.selectionTwo){
      return this.selectionColor
    }else{
      return ''
    }
  }

  selectionOne : number = -1;
  selectionTwo : number = -1;
  selectImg(imageID){
    if(imageID != this.selectionOne && imageID != this.selectionTwo){
      if(this.selectionOne < 0){
        this.selectionOne = imageID;
      }else{
        if(this.selectionTwo < 0){
          this.selectionTwo = imageID
        }
      }
    }else{
      if(imageID == this.selectionOne){
        this.selectionOne = -1;
      }
      if(imageID == this.selectionTwo){
        this.selectionTwo = -1;
      }
    }

    console.log(`Selection 1  : ${this.selectionOne} || Selection 2 : ${this.selectionTwo}`)
    if(this.selectionOne != -1 && this.selectionTwo != -1){
      this.swapArtworks(this.selectionOne , this.selectionTwo)

      this.selectionOne = -1;
      this.selectionTwo = -1;
    }
  }

  getArtworkOfId(id){
    let f = this.all_artworks.filter((artwork) =>{
      return artwork.generated_id == id
    })
    if(f.length == 0 ){
      throw new Error("Searching for missing Id : " + id)
    }else{
      return f[0]
    }
    
  }
  
  swapArtworks(gID_1 : number, gID_2 : number){
    console.log(`Swapping , ${gID_1}  and   ${gID_2}`)
    
    let temp = this.getArtworkOfId(gID_1);
    console.log(`Temp : ${temp}`)

    let indexOf2 = this.all_artworks.indexOf(this.getArtworkOfId(gID_2))
    this.all_artworks[this.all_artworks.indexOf(temp)] = this.getArtworkOfId(gID_2);
    this.all_artworks[indexOf2] = temp;
  }

}
