import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';

import { HttpClientModule } from '@angular/common/http';

import { adminComponent } from './pages/admin/admin.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { NavbarComponent } from './pages/navbar/navbar.component';
import { FormsModule } from '@angular/forms';
import { SectiononeComponent } from './pages/homepage/components/sectionone/sectionone.component';
import { FooterComponent } from './pages/footer/footer.component';
import { GalleryComponent } from './pages/gallery/gallery.component';
import { ArtworkComponent } from './pages/gallery/artwork/artwork.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { GalleryItemComponent } from './pages/admin/gallery-item/gallery-item.component';
import { LoginLogoutComponent } from './pages/admin/login-logout/login-logout.component';
import { NzConfig, NZ_CONFIG } from 'ng-zorro-antd/core/config';
import { GallerySortComponent } from './pages/admin/gallery-sort/gallery-sort.component';
import { NzImageModule } from 'ng-zorro-antd/image';
import { ReadAllSectionComponent } from './pages/homepage/components/read-all-section/read-all-section.component';

registerLocaleData(en);

const ngZorroConfig: NzConfig = {
  message: { nzTop: 120 },
  notification: { nzTop: 240 },
};

@NgModule({
  declarations: [
    AppComponent,
    adminComponent,
    HomepageComponent,
    NavbarComponent,
    SectiononeComponent,
    FooterComponent,
    GalleryComponent,
    ArtworkComponent,
    GalleryItemComponent,
    LoginLogoutComponent,
    GallerySortComponent,
    ReadAllSectionComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    HttpClientModule,
    NzModalModule,
    NzButtonModule,
    NzMessageModule,
    NzImageModule,
    FormsModule,
    BrowserAnimationsModule,
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    { provide: NZ_CONFIG, useValue: ngZorroConfig },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
