import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Artwork } from 'src/app/interfaces/artwork';
import { AuthService } from 'src/app/services/auth.service';
import { FirestoreDBService } from 'src/app/services/firestore-db.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css'],
})
export class GalleryComponent implements OnInit {
  bgUrl: String;
  parralaxBgUrl: String;

  artworks_col_1: Artwork[] = [];
  artworks_col_2: Artwork[] = [];
  artworks_col_3: Artwork[] = [];

  all_artworks: Artwork[] = [];

  constructor(
    private db: FirestoreDBService,
    private route: ActivatedRoute,
    private authService : AuthService
    ) {}

  ngOnInit(): void {
    this.authService.anonymousLogin()
    .then(()=>{
      this.methodsAfterSignIn();
    })
  }

  //Run these after signing in
  methodsAfterSignIn(){
    this.bgUrl = '/assets/banner.png';
    this.parralaxBgUrl = '/assets/paralax2.jpg';

    this.getArtworks().then( () => {
      this.route.params.subscribe( parameter => {
        this.filter(parameter["medium"]);
        console.log("parameter : " , parameter["medium"]);
      })
    })
  }

  getArtworks(){
    return this.db.getArtworkData().then((data: Artwork[]) =>{
      this.all_artworks = data;

      this.sortArtworksToColumns(data , this.allowed_load_amount); 
    })
  }

  allowed_load_amount = 3; //Initially allowed_load_amount will be 3
  sortArtworksToColumns(data : Artwork[] , allowed_load_amount ){

    if(data.length > this.artworks_col_1.length * 3){
      this.artworks_col_1.push(data[this.artworks_col_1.length * 3])
    }
    if(data.length > this.artworks_col_2.length * 3 + 1){
      this.artworks_col_2.push(data[this.artworks_col_2.length * 3 + 1])
    }
    if(data.length > this.artworks_col_3.length * 3 + 2){
      this.artworks_col_3.push(data[this.artworks_col_3.length * 3 + 2])
    }

    // console.log(this.artworks_col_1.length + this.artworks_col_2.length + this.artworks_col_3.length)

  }

  loadMore(){
    if(this.all_artworks.length == this.allowed_load_amount){
      return; //No more images to load
    }

    if(this.all_artworks.length >= 3){
      this.allowed_load_amount += 3;
    }else{
      this.allowed_load_amount = this.all_artworks.length
    }
    
    this.sortArtworksToColumns(this.all_artworks , this.allowed_load_amount)
  }

  lastBottomOffset = 0;
  @HostListener("window:scroll", []) onScroll() {
    // do some stuff here when the window is scrolled
    const verticalOffset = window.pageYOffset 
          || document.documentElement.scrollTop 
          || document.body.scrollTop || 0;
    
    const bottomYoffset = document.getElementById("page-bottom").offsetTop;

    const difference = bottomYoffset - verticalOffset;

    if(difference < 750){
      //Load More
      if(this.lastBottomOffset <= bottomYoffset){
        this.lastBottomOffset = bottomYoffset;
        this.loadMore();
      }

    }
  }

  filter(medium : string) {
    this.db.getArtworkData().then((artworks : Artwork[]) =>{
      console.log("Fetching artworks - Sorting to :" , medium)

      let sorted : any;
      if(medium == "All"){
        sorted = artworks;
      }else{
        sorted = artworks.filter((artwork : Artwork)=>{
          return artwork.art_medium == medium
        })
      }

      this.resetArtworks();
      console.log(sorted);
      this.all_artworks = sorted;
      this.sortArtworksToColumns(sorted , 3)
    })
  }

  resetArtworks(){
    this.artworks_col_1 = []
    this.artworks_col_2 = []
    this.artworks_col_3 = []
    this.lastBottomOffset = 0;
    this.allowed_load_amount = 3;
  }


}
