import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { Artwork } from '../interfaces/artwork';
import { FirestoreDBService } from './firestore-db.service';

@Injectable({
  providedIn: 'root'
})
export class CloudStorageService {

  constructor(
    private cloudStorage: AngularFireStorage,
    private db: FirestoreDBService
  ) { }

  testStorage() {
    return this.cloudStorage.ref("Gallery").listAll()
  }

  uploadOtherImg(file: File , name) {
    return this.cloudStorage.ref(`ExtraImages/${name}`).put(file);
  }

  deleteImg(jsonData , deleteKey){
    delete jsonData[deleteKey];

    let homeLinksRef = this.db.nonGalImgLinks().ref;
    //Remove record from firestore

    this.db.nonGalImgLinks().set(jsonData);
  }


  uploadHomeImg(fileInput :File, key) {
    return new Observable( observer =>{
      let t = Date.now()
    let imgURL = ""
    observer.next("Uploading File");
    this.uploadOtherImg(fileInput , key)
      .then((uploadRes) => {
        observer.next("File Uploaded");
        return uploadRes.ref.getDownloadURL();
      })
      .then(url => {
        observer.next(`URL : ${url}`);
        imgURL = url;
        return this.db.nonGalImgLinks().get().toPromise()
      })
      .then(links => {
        let newData = links.data();
        if(newData == undefined){
          newData = {}
        }

        observer.next(`Input Json : ${JSON.stringify(newData)}`)
        newData[key] = imgURL;
        observer.next(`Altered Json : ${JSON.stringify(newData)}`)

        return this.db.nonGalImgLinks().set(newData)
      })
      .then(() => {
        observer.next("Record Updated in Firestore");
        observer.next(`Time taken : ${(Date.now() - t) / 1000}`);
        observer.next("complete");
        // resolve((Date.now() - t) / 1000);
      })
    })
  }

  uploadGalleryImg(file : File , artwork_id : number){
    let imgURL = ""

    //Image Upload
    this.cloudStorage.ref(`Gallery/${artwork_id}`).put(file)
      .then((uploadRes) => {
        return uploadRes.ref.getDownloadURL(); //Return Img URL
      })
      .then(url => {
        imgURL = url;
        return this.db.getArtworkData() //Get Artwork Data
      })
      .then((data : Artwork[]) => {
        let newData : Artwork[] = [];

        data.forEach((map : Artwork)=>{
          if(map.generated_id == artwork_id){
            map.highRes_imgURL = imgURL;
            newData.push(map);
          }else{
            newData.push(map);
          }
        })

        return this.db.setArtworkData(newData); // Update URL of the artwork
      })
  }

}
