<app-navbar></app-navbar>
<div class="page">
  <div class="banner" id="Hero" style="background-image: url({{bgUrl}});">
    <!-- <h1>Swendrini Gunasekara Art</h1> -->
    <img id="nametxt" src="assets/gallerytxt.png" alt="title" />
  </div>

  <div class="filter">
    <p [routerLink]="['/ARTGallery','All']">All</p>
    <p [routerLink]="['/ARTGallery','Oils']">Oils</p>
    <p [routerLink]="['/ARTGallery','Acrylics']">Acrylic</p>
    <p [routerLink]="['/ARTGallery','Water Colour']">Water Color</p>
    <p [routerLink]="['/ARTGallery','Charcoal']">Charcoal</p>
    <p [routerLink]="['/ARTGallery','Pen&Ink']">Pen & Ink</p>
    <p [routerLink]="['/ARTGallery','Other']">Other..</p>
  </div>

  <div class="artrow" (scroll)="onScroll()">
    <div class="artcol">
      <app-artwork
        *ngFor="let artwork_1 of artworks_col_1"
        [artwork_data]="artwork_1"
      ></app-artwork>
    </div>

    <div class="artcol">
      <app-artwork
        *ngFor="let artwork_2 of artworks_col_2"
        [artwork_data]="artwork_2"
      ></app-artwork>
    </div>

    <div class="artcol">
      <app-artwork
        *ngFor="let artwork_3 of artworks_col_3"
        [artwork_data]="artwork_3"
      ></app-artwork>
    </div>
  </div>
  <div id="page-bottom"></div>
  <app-footer></app-footer>
</div>
