import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { FirestoreDBService } from 'src/app/services/firestore-db.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {

  bgUrl:String
  parralaxBgUrl:String;

  card_charcoal : string;
  card_oils : string;
  card_other : string;

  constructor(
    private db : FirestoreDBService,
    private authService : AuthService
  ) { }

  ngOnInit(): void {
    this.authService.anonymousLogin()
    .then(()=>{
      //Content must be loaded only after user has been logged in anonymously
      this.loadPageContent()
    })
    
  }

  loadPageContent(){
    this.db.nonGalImgLinks().get().toPromise().then(linksRes =>{
      let data = linksRes.data();
      this.bgUrl = data["hero-img"];
      this.parralaxBgUrl = data["main-gal"];
      this.card_charcoal = data["card-1"];
      this.card_oils = data["card-2"];
      this.card_other = data["card-3"];
    });

  }

  

}
