<div class="main">
  <div class="sec2-left">
    <a href="#"><img src="assets/leftimg.png" alt="profile picture" /></a>
  </div>

  <div class="sec2-right">
    <div id="sec1text">
      <p>
        With her artistic eye and her flair for writing, this artist has worked
        in both the fields of Advertising and Journalism in addition to her
        painting. She holds a Bachelors of Science Degree in Advertising and
        Communication from the University of Texas at Austin, USA. The study of
        art was an essential component of her degree while at university. In
        addition, she has studied art throughout her childhood and has decades
        of experience as a painter. <br />
        Having exhibited her work many times in Sri Lanka’s prestigious Art
        Galleries, Her most recent solo exhibition was held in September 2019
        where she exhibited over a hundred pieces of work in Colombo, Sri Lanka.
        Her work has sold in both her home country and internationally.
        <br />
        Currently based in Colombo, Sri Lanka, Swendrini is now a full time
        artist and paints from a studio within her home.
        <br />
        Whilst most of her work reflects Realism, her most recent work explores
        a more Modernistic style of expression. ‘Flying Jewels’, ‘Blue Lotus’,
        ‘Scarlets’ are amongst some of her more recent pieces of work that
        reflect a beautiful marriage between Modern art and Realism
        <br />
        Welcome!...and please feel free to wonder through the Gallery of this
        fascinating artist and her beautiful and enchanting work.
        <br />
        Let this amazing artwork inspire you, relax you, mesmerise and tantalise
        you...Most of all enjoy your journey as the art carries you from
        wherever you may be; Savour your journey as you are transported to the
        artist’s magical tropical island through her work.
        <br />
      </p>
      <p>
        <b
          >“ART is something that makes you breath with a different kind of
          happiness…”
        </b>
      </p>
    </div>

    <div id="smicons">
      <a href="https://www.instagram.com/artbyswen/"
        ><img src="assets/smlogo (2).png" alt="instragram"
      /></a>
      <a href="https://www.facebook.com/Swendrini-Artist-655051038331086/"
        ><img src="assets/smlogo (3).png" alt="facebook"
      /></a>
      <a
        ><img
          src="assets/smlogo (4).png"
          alt="whatsapp"
          (click)="createBasicMessage()"
      /></a>
    </div>
  </div>
</div>
