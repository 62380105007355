import { Component, OnInit } from '@angular/core';
import { FirebaseApp } from '@angular/fire';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-login-logout',
  templateUrl: './login-logout.component.html',
  styleUrls: ['./login-logout.component.css']
})
export class LoginLogoutComponent implements OnInit {

  signedIn : boolean = false;

  email : string = "";
  password : string = "";

  login_error_cause : string = "";

  constructor(
    private firebaseAuth : AngularFireAuth
  ) { }

  ngOnInit(): void {
    this.checkSignedIn();
  }
  

  checkSignedIn(){
    if(localStorage.getItem("user") != null){
      this.signedIn = true;
    }
  }

  login(){
    this.firebaseAuth.signInWithEmailAndPassword(this.email , this.password)
      .then(res =>{
        this.signedIn = true;
        localStorage.setItem("user" , JSON.stringify(res))
      })
      .catch((res)=>{
        this.updateLoginErrorCause(res.code);
      })
  }


  logOut(){
    this.firebaseAuth.signOut()
    localStorage.removeItem("user")
    this.signedIn = false;
  
    this.email = "";
    this.password = "";
    this.login_error_cause = "";
  }


  updateLoginErrorCause(error_code : string){
    console.log(error_code)

    switch(error_code){
      case "auth/invalid-email":
        this.login_error_cause = "Invalid Email Format";
        return;
      case "auth/user-not-found":
        this.login_error_cause = "Invalid Email";
        return
      case "auth/wrong-password":
        this.login_error_cause = "Invalid Password";
        return
      default:
        this.login_error_cause = "Unknown Error";
    }
  }
}
