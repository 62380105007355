<div id="section1">
  <div class="main">
    <div class="sec1-left">
      <img id="sec1title" src="assets/sec1title.png" alt="Artist's Story" />
      <div id="sec1text">
        <p>
          Swendrini is at the heart of hearts an island girl, and this is the
          strongest vibe that resonates in her work as an artist. The reflection
          of island life in her artwork is as natural as holding a palette and
          brush to her. <br />
          From the colours used, to the themes explored, this artist reflects a
          deeply personal experience through her paintings. Seascapes to
          Frangipani, Temples to Tuk Tuks, Elephants to Sunbirds...To wander
          through her Gallery is to take a walk in a tropical heaven!
        </p>

        <p *ngIf="displayAll == false">
          As an artist her work reflects depth and understanding of a wide and
          varied use of mediums...
        </p>

        <p *ngIf="displayAll == true">
          As an artist her work reflects depth and understanding of a wide and
          varied use of mediums...From the complexities of Oils, To the
          intricacies of Pen and Ink and everything in between, Swendrini
          experiments and mixes and matches her art with much expertise and
          artistic flair. As much as she does not allow her themes and interests
          to limit her, neither does this artist allow the medium of painting to
          limit her work and expression.
        </p>

        <p
          class="readMore"
          *ngIf="displayAll == false"
          (click)="showMoreText()"
        >
          Read more
        </p>
        <br />
        <p *ngIf="displayAll == false">
          <b
            >“ART is something that makes you breath with a different kind of
            happiness…”
          </b>
        </p>
      </div>
      <div id="smicons" *ngIf="displayAll == false">
        <a href="https://www.instagram.com/artbyswen/"
          ><img src="assets/smlogo (2).png" alt="instragram"
        /></a>
        <a href="https://www.facebook.com/Swendrini-Artist-655051038331086/"
          ><img src="assets/smlogo (3).png" alt="facebook"
        /></a>
        <a
          ><img
            src="assets/smlogo (4).png"
            alt="whatsapp"
            (click)="createBasicMessage()"
        /></a>
      </div>
    </div>
    <div class="sec1-right">
      <a href="#"><img src="assets/profilepic.png" alt="profile picture" /></a>
    </div>
  </div>
  <div>
    <div *ngIf="displayAll == true">
      <app-read-all-section></app-read-all-section>
      <a class="readMore" href="Home#section1">
        <p class="readMore" *ngIf="displayAll == true" (click)="showLessText()">
          Read less
        </p>
      </a>
    </div>
  </div>
</div>
